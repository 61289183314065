module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Acces Construction","short_name":"Acces","description":"Rénovation en minervois","start_url":"/","background_color":"#ffffff","theme_color":"#f33434","display":"standalone","icon":"src/images/logo_icon.png","lang":"fr","localize":[{"start_url":"/en/","lang":"en","name":"Acces Construction","short_name":"Acces","description":"Renovation in Minervois"},{"start_url":"/nl/","lang":"nl","name":"Acces Construction","short_name":"Acces","description":"Renovatie in Minervois"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74c4056bb67a33879aa0a5a8d2631216"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald","Poppins"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/locales","languages":["en","fr","nl"],"defaultLanguage":"fr","siteUrl":"https://accesconstruction.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
